import React from 'react';
import CookieConsent from "react-cookie-consent";
import styles from './Home.module.css';
import SimpleLayout from '../../../components/Common/SimpleLayout/SimpleLayout';
import Hero from '../../components/Home/Hero/Hero';
import Contacto from '../../components/Home/Contacto/Contacto';
import App from '../../components/Home/App/App';
import Patologias from '../../components/Home/Patologias/Patologias';
import { HashLink } from 'react-router-hash-link';


const Home = () => (
  <div className={styles.Home}>
    <SimpleLayout>
      <>
        <div className="d-flex flex-column">

          <Hero></Hero>

          <Contacto></Contacto>

          {/* <App></App> */}

          <Patologias></Patologias>

        </div>
      </>
      <CookieConsent
        cookieName="acepteCookie"
        buttonStyle={{ color: "white", fontSize: "1.231rem", background: '#F36633', borderRadius: '6px', fontWeight: 'bold' }}
        buttonText="ACEPTAR">
        ¡Usamos cookies para optimizar la funcionalidad de PSP y proporcionarle una experiencia más segura. Si cierras este mensaje o continúas usando PSP, estás indicando que aceptas esto <HashLink to="/condiciones-de-uso#content7-1">MÁS INFORMACIÓN</HashLink>
        </CookieConsent>

    </SimpleLayout>
  </div>
);

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
