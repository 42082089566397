import React from 'react'
import SimpleLayout from '../../../components/Common/SimpleLayout/SimpleLayout'
import styles from './PoliticaDeCookies.module.css'
const PoliticasDeCookies = () => (
    <div className={styles.PoliticasDeCookies}>
        <SimpleLayout>
        <> 
         <div className={`d-flex flex-column-fluid ${styles.content}`}>
            <div className="container">
                <div className="row">
                    <div className="col-12 mt-15">
                        <h1 style={{ fontSize: 40, textAlign: "center", color: '#F36633' }} className="mb-15 text-uppercase font-weight-boldest">Política de Cookies</h1>                
                        <p>
                            GSK utiliza cookies para mejorar su experiencia en PSP. Algunas de las cookies que utilizamos son esenciales para que algunas funcionalidades de PSP funcionen de manera adecuada. 
                        </p>
                        <p>
                            GSK utiliza cookies para mejorar la experiencia de los visitantes y usuarios de PSP. A continuación, se explica qué son las cookies y cómo las utilizamos en PSP. La instalación, permanencia y existencia de “cookies” en su computador depende de su exclusiva voluntad y pueden ser eliminadas de su computador cuando usted así lo desee. Para saber cómo quitar las “cookies” del sistema es necesario seguir las instrucciones en la sección Ayuda (Help) de su navegador.
                        </p>
                        <p>
                            Las cookies son pequeños archivos, compuestos por una cadena de letras y números, colocados en su computadora por los servidores de páginas web. Permiten al propietario del sitio web distinguirlo de otros usuarios de la página web y proporcionan a GSK información relativa a la frecuencia con que usted visita nuestro Sitio y sobre que se hace durante dichas visitas. Las cookies no se pueden ejecutar como código o utilizarse para enviar virus y no nos permiten acceder a su disco duro. No podemos leer ninguna información de su disco duro, incluso si almacenamos cookies allí. De esta manera, las cookies no contienen, por sí mismas, datos de carácter personal pero si Usted nos proporciona esos datos, por ejemplo a través de su registro en PSP, dichos datos se podrán vincular a los datos almacenados en los ficheros cookies.
                        </p>
                        <p>
                            Cualquier información del usuario que se recolecte por medio de cookies se tratará conforme a esta Política de Privacidad. Si Ud. ha configurado su ordenador para que le informe antes de aceptar cookies, recibirá un mensaje de advertencia antes de cada cookie. Puede rechazar las cookies desactivándolos en su explorador. Por favor, tenga en cuenta que si acepta nuestra Política de Privacidad y no cambia la configuración de sus “cookies”, usted estará otorgando su consentimiento expreso para el uso de las “cookies” en PSP. Tenga en cuenta que PSP podría no funcionar correctamente con las cookies desactivadas.
                        </p>
                        <p>
                            Los datos personales recolectados a través de cookies serán utilizados para las siguientes finalidades: (i) cookies necesarias para que el usuario pueda utilizar PSP y sus características, tales como el acceso a áreas seguras, (ii) cookies de rendimiento que recolectan información sobre el uso de PSP de los usuarios y visitantes, por ejemplo, las páginas que los visitantes ven con frecuencia y si reciben mensajes de error. Se utilizan para mejorar el funcionamiento del sitio web en versiones futuras. (iii) cookies de funcionalidad que permiten a PSP recordar decisiones del usuario como por ejemplo el nombre de usuario, preferencias de idioma, donde se encuentra y proporcionar una experiencia personal más reforzada y (iv) cookies de segmentación o publicidad que se utilizan para entregar contenido relevante a usted de acuerdo con los intereses del usuario.
                        </p>
                        <p>
                            No permitimos que ningún tercero haga publicidad en nuestro sitio web, pero no podemos controlar los anunciantes que están permitidos por los proveedores de servicios de Internet para hacer publicidad mientras está navegando en Internet.
                        </p>
                        </div>        
                    </div>
                </div>
            </div>
            </>
        </SimpleLayout>    
    </div>	

)

export default PoliticasDeCookies