import React from 'react';
import PropTypes from 'prop-types';
import styles from './Contacto.module.css';

const Contacto = () => (
  <section id="contacto" className="mb-20">
  <div className="container">
    <div className="row">
      <div className="col-12 d-flex flex-column justify-content-center align-items-center py-20 px-5 px-lg-40 text-center info-contacto">
        <h6 className="px-0 px-lg-40 mb-6 mb-lg-3 font-georgia text-black-50"><i>Hoy más que nunca reforzamos nuestro principal objetivo:</i></h6>
        <h3 className="px-0 px-lg-40 mb-20 font-georgia line-height-lg"><i>Acompañar a sus pacientes en el camino hacia su <span className="text-primary">meta terapéutica</span>, colaborando en mantener una <span className="text-primary">adecuada adherencia</span> y mejorando su <span className="text-primary">calidad de vida</span>.</i> </h3>
        <hr className="w-200px mb-20 mt-0"></hr>
        <p className="text-uppercase mb-0"><b>Linea de contacto gratuita:</b></p>
        <h2 className="mb-0"><a href="tel:08007770107"><b>0800-777-0107</b></a></h2>
        <small className="mb-20"><b>Lunes a Viernes de 9.00 a 18.00 hs</b></small>
        <p className="text-uppercase mb-0"><b>Dirección de E-Mail:</b></p>
        <h3><a href="mailto:programamas@pharmexxarg.com">programamas@pharmexxarg.com</a></h3>
      </div>
    </div>
  </div>
</section>
);

Contacto.propTypes = {};

Contacto.defaultProps = {};

export default Contacto;
