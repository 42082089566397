import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from 'firebase/app';

// Use your config values here.
const firebaseConfig ={
  apiKey: "AIzaSyAYqsHNzuGc7Em7Nsw6Q3X1xDLpRxiHU6Y",
  authDomain: "gsk-portal-mas.firebaseapp.com",
  projectId: "gsk-portal-mas",
  storageBucket: "gsk-portal-mas.appspot.com",
  messagingSenderId: "434966650043",
  appId: "1:434966650043:web:65762af32c26cf68371d9e",
  measurementId: "G-DVEHL9D9NB"
}

const app = initializeApp(firebaseConfig);


const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
