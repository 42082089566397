import React from 'react';
import styles from './TerminosYCondiciones.module.css';
import SimpleLayout from '../../../components/Common/SimpleLayout/SimpleLayout';


const TerminosYCondiciones = () => (
  <div className={styles.CondicionesDeUso}>
    <SimpleLayout>
      <>
        <div className={`d-flex flex-column-fluid ${styles.content}`}>
          <div className="container">
            <div className="row">
              <div className="col-12 mt-15">
                <h1 style={{ fontSize: 40, textAlign: "center", color: '#F36633' }} className="mb-15 text-uppercase font-weight-boldest">Terminos y Condiciones</h1>
                <div class="col-12 col-md-10 offset-md-1 px-3 px-sm-4 px-md-3">    
                <h3 id="content1" className={styles.title}>
                                1.  Aviso Legal
                            </h3>
                            <p class="text-brown text-justify">
                                Los presentes términos y condiciones regulan el acceso, uso, navegación, registro y/o descarga
                                del sitio web [“https://programamas.com.ar/”] (el “Sitio”) de propiedad de GSK Biopharma Argentina S.A., 
                                con domicilio legal en Avda. del Libertador 7202, Piso 4to., Ciudad Autónoma de Buenos Aires (1429), República Argetina
                                (“GSK”).<br/>
                                LOS TÉRMINOS Y CONDICIONES Y EL AVISO DE PRIVACIDAD DEL SITIO SON
                                VINCULANTES (las “Políticas”). POR FAVOR LEA ATENTAMENTE LAS POLÍTICAS
                                DEL SITIO ANTES DE INGRESAR Y/O NAVEGAR Y/O UTILIZAR Y/O DESCARGAR
                                Y/O REGISTRARSE EN EL SITIO. EL SITIO FUE DESARROLLADO, ES DE PROPIEDAD
                                Y ES MANTENIDO POR GSK. MEDIANTE EL USO DEL SITIO, USTED ACEPTA LAS
                                POLÍTICAS. NO HAGA USO DEL SITIO SI NO ESTÁ DE ACUERDO CON LAS
                                POLÍTICAS.
                                </p>
                                <p class="text-brown text-justify mb-4-5">
                                A su absoluta discreción, GSK se reserva el derecho a cambiar, modificar, y actualizar las Políticas en
                                cualquier momento. En caso de modificaciones materiales a las Políticas, GSK lo informará en el
                                Sitio – a su exclusivo criterio – y se entenderá que el usuario acepta esos cambios a las Políticas si
                                continúa usando el Sitio luego de la fecha de su entrada en vigor.
                            </p>
                            <h3 id="content1" className={styles.title}>
                                2.  Usuarios permitidos
                            </h3>
                            <p class="text-brown text-justify mb-4-5">
                            El Sitio está exclusivamente destinado para ser utilizado por personas que hayan sido previamente
                            inscritas en el programa de soporte de pacientes de GSK denominado Mejor Acceso a la Salud (el
                            “Programa”) en virtud de cumplir con los requisitos de admisibilidad del mismo. En caso de que usted
                            no esté dentro de esa audiencia, no deberá acceder ni utilizar el Sitio en ningún supuesto. En caso de
                            que lo haga de todos modos, Usted será exclusivamente responsable por ello y deberá mantener
                            indemne a GSK de cualquier y todo reclamo que reciba en relación con su acceso y uso indebido del
                            Sitio. Al utilizar el Sitio, Usted está declarando ser un paciente que se encuentra actualmente
                            participando del Programa. Aquellas personas que decidan acceder al Sitio lo hacen según su propia
                            iniciativa y bajo su propia responsabilidad y son responsables del uso que hagan del mismo y de
                            cumplimiento de las leyes aplicables.
                            </p>
                            <h3 id="content1" className={styles.title}>
                                3.  Sitios de Terceros
                            </h3>
                            <p class="text-brown text-justify mb-4-5">
                            A través de los vínculos o referencias que posee el Sitio, usted puede conectarse con otros sitios o
                            plataformas de GSK y/o de terceros. GSK no se responsabiliza por la información que pueda
                            obtenerse en sitios o plataformas de terceros que no estén bajo su control ni son respaldados por GSK.
                            Aunque GSK trata de facilitar el enlace con páginas de terceros que cumplen todos los requisitos y
                            normas legales pertinentes, así como las normas de GSK, ha de entenderse que el contenido de dichas
                            páginas de terceros puede variar sin que GSK tenga conocimiento de ello. Por lo tanto, GSK no se
                            responsabiliza, ni aceptar ningún tipo de responsabilidad, de la información que se proporciona, ni de
                            las opiniones que se expresan en las páginas de Internet de terceros, como así tampoco por la
                            disponibilidad de los contenidos de estos o por los daños y perjuicios que pudieran resultar de su uso.

                            </p>
                            <h3 id="content1" className={styles.title}>
                                4.  Información y Contenidos en el Sitio
                            </h3>
                            <p class="text-brown text-justify">
                                Los nombres y descripciones de los productos están dirigidos a las personas humanas residentes
                                en Argentina. Los productos comercializados en otros países bajo la misma marca podrían tener
                                formulas diferentes. GSK no se hace responsable por ningún daño que sea producto del acceso
                                y/o uso del Sitio o cualquier otro sitio vinculado al Sitio, ni por el uso que se haga de la
                                información contenida en el Sitio o cualquier otro sitio o plataforma vinculada.
                            </p>
                            <p class="text-brown text-justify">
                                La información contenida en el Sitio no debe ser tomada como consejo médico, diagnóstico o
                                tratamiento. El contenido disponible en el Sitio en ninguna forma pretende proporcionar una
                                respuesta o solución a problemas de salud de las personas. Tampoco proporciona diagnósticos,
                                tratamientos o formas de prescribir el producto para cada caso particular, por lo que se
                                recomienda, en cualquier caso, ante cualquier duda, consultar con un profesional de la salud.
                            </p>
                            <p class="text-brown text-justify">
                                Ninguna información publicada o disponible a través del Sitio supone un asesoramiento,
                                calificación o sugerencia de compra o de venta. La información que usted encontrará en el Sitio
                                no sustituye la consulta profesional con un profesional de la salud. Publicamos la misma con el
                                entendimiento de que no será interpretada como consejo médico o profesional. Toda la
                                información brindada necesita ser revisada cuidadosamente por usted y su médico. No debe
                                desacreditar o dilatar la consulta de un médico por causa de cualquier información contenida en
                                el Sitio.
                            </p>
                            <p class="text-brown text-justify">
                                En concreto, los resultados y los hechos reales pueden resultar totalmente distintos a los
                                previstos o esperados en el Sitio, por lo que su valoración en el pasado no debe constituir ningún
                                tipo de base o guía sobre la que puedan tener en el futuro.
                            </p>
                            <p class="text-brown text-justify mb-4-5">
                                El autor de la información contenida en el Sitio (GSK) y/o cualquier otra persona humana,
                                entidad o institución que intervenga o haya intervenido en su preparación, difusión y/o estén
                                involucradas en cualquier otra forma con ésta, se reservan el derecho de actualizar, cancelar y/o
                                de cualquier forma modificar en todo momento el contenido total o parcial de dicha
                                información, sin previo aviso y sin responsabilidad alguna.
                            </p>                            
                            <h3 id="content1" className={styles.title}>
                                5.  Propiedad Intelectual
                            </h3>
                            <p class="text-brown text-justify">
                                Los derechos de propiedad intelectual y cualesquiera otros derechos sobre el material e
                                información publicada y disponible en el Sitio pertenecen exclusivamente a GSK y se
                                encuentran protegidos por la legislación sobre propiedad intelectual. No se permite la
                                reproducción total o parcial del Sitio, sus contenidos, ni su traducción, ni su incorporación a un
                                sistema informático, ni su locación, ni su transmisión en cualquier forma o por cualquier medio,
                                sea éste electrónico, mecánico, por fotocopia, por grabación, u otros métodos, sin el previo y
                                expreso consentimiento de GSK. La violación de cualquiera de estos derechos exclusivos de
                                GSK constituye una violación e implica responsabilidad del infractor que dará lugar a sanciones
                                civiles o criminales. GSK solo autoriza a utilizar copias de los documentos e información
                                contenidos en el Sitio con fines exclusivamente privados y domésticos.
                            </p>
                            <p class="text-brown text-justify">
                                Cualquier copia, reproducción, distribución, publicación, descarga, exhibición, publicación,
                                transmisión o utilización de copias con la información y los contenidos disponibles en el Sitio
                                está expresamente prohibida. El contenido de la información no puede ser modificada,
                                distribuida, reenviada, transmitida, reutilizada para fines comerciales y/o públicos, sin el
                                consentimiento previo y expreso de GSK. Excepto por cuanto expresamente se ha especificado
                                anteriormente, nada de cuanto aquí se incluye confiere ningún tipo de licencia o derecho en
                                virtud de los derechos de propiedad intelectual que posee GSK sobre los contenidos en
                                información publicados y/o disponibles en el Sitio.
                            </p>
                            <p class="text-brown text-justify">
                                
                                Asimismo, nada de cuanto aquí se dice ha de entenderse que confiera, de forma implícita, por
                                exclusión o de otra forma, licencia o derecho a terceros sobre cualquier patente, derecho de
                                autor o marca de GSK. Las marcas, marcas de servicios, logos e isologotipos exhibidas,
                                utilizadas o contenidas en el Sitio (“Marcas”) son propiedad de GSK o de terceros que han
                                otorgado la correspondiente licencia o autorización de uso a favor de GSK. GSK no reivindica la
                                propiedad de las marcas registradas de terceros que aparecen en el Sitio. Dichas marcas
                                registradas únicamente se utilizan con el fin de identificar los productos y los servicios de sus
                                respectivos propietarios, y no debe deducirse del empleo de estas marcas que GSK las
                                promocione o las avale. Específicamente, no se podrá utilizar la Marcas sin consentimiento
                                previo, expreso y por escrito de GSK.
                            </p>
                            <p class="text-brown text-justify">

                                El usuario reconoce y acepta que todos los derechos intelectuales e industriales del Sitio
                                (incluyendo sin limitación derechos sobre las Marcas, propiedad intelectual, derecho de autor,
                                derecho industrial, patentes y demás derechos patrimoniales y morales), son y serán
                                considerados de propiedad exclusiva de GSK.
                            </p>
                            <p class="text-brown text-justify">
                                En caso que cualquier usuario del Sitio ponga a diposisición o entregue a GSK o publique en el
                                Sitio cualquier tipo de información, incluyendo aunque sin limitarse a sugerencias, planes, ideas,
                                conceptos, experiencias, técnicas, comentarios, preguntas o respuestas (los “Contenidos de
                                Usuario”), el usuario declara y garantiza que dicha información no es confidencial ni infringe
                                derechos de terceros y que cede en forma gratuita, exclusiva, irrevocable, absoluta, con alcance
                                mundial y por tiempo indeterminado todos los derechos de propiedad indutrial e intelectual
                                sobre los Contenidos de Usuario a favor de GSK. De la misma manera, el usuario declara que
                                renuncia completa e irrevocablemente a invocar lesión alguna sobre derechos de paternidad y/o
                                integridad sobre los Contenidos de Usuario entregados a GSK. Por ello, GSK tendrá derechos de
                                carácter ilimitado para usar o no usar, distribuir o no distribuir, reproducir o no reproducir y revelar o no revelar 
                                los Contenidos de Usuario de la forma y modo que GSK considere
                                apropiado con cualquier fin o propósito, incluyendo pero sin limitarse al desarrollo, fabricación
                                y la comercialización de productos que de alguna manera sean fruto de los Contenidos de
                                Usuario, sin que ello genere derecho a indemnización o compensación alguna ni a reconocimiento de su fuente.

                            </p>
                            <p class="text-brown text-justify mb-4-5">
                                GSK y/o cualquier otra persona o institución que intervenga o haya intervenido en su
                                preparación, difusión y/o estén involucradas en cualquier otra forma en la preparación del
                                contenido publicado en el Sitio, se reservan el derecho de utilizar, cancelar y/o de cualquier
                                forma modificar en todo momento el contenido total o parcial de dicha información, sin previo
                                aviso y sin responsabilidad alguna.                                
                            </p>

                            <h3 id="content1" className={styles.title}>
                                6.  Interrupción del servicio. Ataques de terceros.
                            </h3>
                            <p class="text-brown text-justify mb-4-5">
                                GSK no garantiza la inexistencia de eventuales dificultades técnicas, errores o fallas en la red de
                                telecomunicaciones o en Internet, como así tampoco garantiza la disponibilidad,
                                funcionamiento, el acceso y uso continuado o ininterrumpido del Sitio. El Sitio puede
                                eventualmente no estar disponible por actividades de mantenimiento, debido a dificultades
                                técnicas, de la red de telecomunicaciones o fallas de Internet, o por cualquier otra circunstancia
                                ajena a GSK, que no generará responsabilidad alguna por parte de GSK. En tales casos se
                                procurará restablecerlo con la mayor celeridad posible. Asimismo, GSK se reserva el derecho de
                                interrumpir, suspender, discontinuar o modificar en cualquier momento el Sitio, sea en forma
                                permanente o transitoria, sin notificación previa.                                
                                <br/>
                                Queda a exclusivo riesgo del usuario el uso de Sitio. Ni GSK, ni ninguna persona humana o
                                jurídica vinculada a la creación, producción, desarrollo o utilización del Sitio será responsable
                                por daños de especie alguna que puedan resultar de ese uso, así como tampoco será responsable
                                de cualquier daño a su equipo de computación o dispositivos por cualquier virus que pueda
                                infectarlo como consecuencia del uso y acceso al Sitio.
                            </p>
                        </div>
              </div>  
            </div>
          </div>
        </div>
      </>
    </SimpleLayout>
  </div>
);

TerminosYCondiciones.propTypes = {};

TerminosYCondiciones.defaultProps = {};

export default TerminosYCondiciones;
