import React, { useEffect, useRef, useState } from "react";
import styles from "./Enrolamiento.module.css";
import TextInput from "../../../components/Common/Input/TextInput";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import FormLayout from "../../../components/Common/FormLayout/FormLayout";
import Dropdown from "../../../components/Common/Dropdown/Dropdown";
import { registrar } from "../../../api/api.js";
import { notify } from "../../../lib/notify";
import { ModalConsentimiento } from './modal-consentimiento';
import { Success } from './success';

const Enrolamiento = () => {
  const [success, setSuccess] = useState(false);
  const [productos, setProductos] = useState([]);
  const [indicaciones, setIndicaciones] = useState([]);
  const [show, setShow] = useState(false);
  const refLi = useRef(null);
  const [name, setName] = useState("");
  const [lastName, setLastname] = useState("");
  const [gender, setGender] = useState("");
  const [bornDate, setBornDate] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [dni, setDni] = useState("");
  const [hasMedication, setHasMedication] = useState("");
  const [product, setProduct] = useState(null);
  const [indication, setIndication] = useState(null);
  const [acceptConditions, setAcceptConditions] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/programa')
      .then(response => response.json())
      .then(data => {
        setProductos(data.productos)
      });
  }, []);

  const toggleModal = () => {
    setSubmitted(true);
    setDisabled(true)
    if (isFormValid()) {
      setShow((prev) => !prev);
    }else{
      setDisabled(false)
    }
  };

  // Validation hooks

  const handleChangeProduct = (e) => {
    setProduct(e.target.value);
    let selectedProduct = productos.filter((obj) => {
      return obj.id == e.target.value;
    })[0];
    setIndicaciones(null);
    setProduct(selectedProduct)
    setIndicaciones(selectedProduct.indicaciones);
    setIndication('')
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      nombre: name,
      apellido: lastName,
      sexo: gender,
      fechaNacimiento: bornDate,
      email: email,
      telefono: phone,
      tipoDocumento: documentType,
      nroDocumento: dni,
      producto: Number(product.id),
      indicacion: Number(indication),
      accesoProductoFinalizado: hasMedication == "si",
    };
    toggleModal();
    const response = await registrar(data)
    if (response && response.status === 'success') {
      notify("success", "Se ha registrado con exito!");
      setSuccess(true);
    } else {
      if (response && response.message) {
        notify("error", `Error: ${response.message}`);
      } else {
        notify("error", `Error: Ups! Hubo un error inesperado`);
      }
    }
  };

  const isFormValid = () => {
    let result = true;

    result = result && name;
    result = result && lastName;
    result = result && gender;
    result = result && bornDate;
    result = result && email;
    result = result && phone;
    result = result && documentType;
    result = result && dni;
    result = result && hasMedication;
    result = result && product;
    result = result && indication;
    result = result && acceptConditions;

    return result;
  };

  const onMedicationValueChange = (e) => {
    setHasMedication(e.target.value);
  };
  return (
    <div className={styles.Enrolamiento}>
      <>
        <FormLayout>
          <div className={`login-form login-signup ${styles.FormContainer}`}>
            {success ? (
              <Success />
            ) : (
              <form onSubmit={handleSubmit} className="form d-flex flex-wrap">
                <input type="hidden" name="action" value="register" />
                <div className="pb-8 pt-lg-0 pt-5 w-100">
                  <h3 className="font-weight-boldest font-size-h1 text-uppercase text-left">
                    Registro
                  </h3>
                </div>
                <div className="form-group half first">
                  <TextInput
                    onChangeText={setName}
                    isRequired
                    hasError={!name && submitted}
                    label="Nombre"
                    errorMessage="Por favor ingrese su nombre"
                    name="Nombre"
                    value={name}
                  />
                </div>
                <div className="form-group half">
                  <TextInput
                    onChangeText={setLastname}
                    isRequired
                    hasError={!lastName && submitted}
                    label="Apellido"
                    errorMessage="Por favor ingrese su apellido"
                    name="Apellido"
                    value={lastName}
                  />
                </div>
                <div className="form-group half first">
                  <Dropdown
                    onChange={(e) => setGender(e.target.value)}
                    isRequired
                    hasError={!gender && submitted}
                    label="Genero"
                    errorMessage="Por favor seleccione su genero"
                    name="genero"
                    items={[
                      { nombre: "", id: "" },
                      { nombre: "Femenino", id: "F" },
                      { nombre: "Masculino", id: "M" },
                    ]}
                  />
                </div>
                <div className="form-group half">
                  <TextInput
                    onChangeText={setBornDate}
                    type="date"
                    isRequired
                    hasError={!bornDate && submitted}
                    label="Fecha de nacimiento"
                    errorMessage="Por favor ingrese su fecha de nacimiento"
                    name="Apellido"
                    value={bornDate}
                  />
                </div>
                <div className="form-group">
                  <TextInput
                    onChangeText={setEmail}
                    labelStyle={{ float: "left" }}
                    isRequired
                    hasError={!email && submitted}
                    label="Email"
                    errorMessage="Por favor ingrese su email"
                    name="Email"
                    value={email}
                  />
                </div>
                <div className="form-group">
                  <TextInput
                    onChangeText={setPhone}
                    isRequired
                    hasError={!phone && submitted}
                    label="Telefono"
                    errorMessage="Por favor ingrese su telefono"
                    name="Telefono"
                    value={phone}
                  />
                </div>
                <div className="form-group half first">
                  <Dropdown
                    onChange={(e) => setDocumentType(e.target.value)}
                    isRequired
                    hasError={!documentType && submitted}
                    label="Tipo de documento"
                    errorMessage="Por favor seleccione su tipo de documento"
                    name="tipodocumento"
                    items={[
                      { nombre: "", id: "" },
                      { nombre: "DNI", id: "dni" },
                      { nombre: "LE", id: "le" },
                      { nombre: "LC", id: "lc" },
                    ]}
                  />
                </div>
                <div className="form-group half">
                  <TextInput
                    onChangeText={setDni}
                    isRequired
                    hasError={!dni && submitted}
                    label="N° de documento"
                    errorMessage="Por favor ingrese su documento"
                    name="documento"
                    value={dni}
                  />
                </div>
                <div className="form-group row text-left">
                  <label className="col-7 col-form-label font-size-h6">
                    ¿Ya cuenta con la medicación?{" "}
                    <span className="required"> *</span>
                    <div className="errorTxt"></div>
                  </label>
                  <div className="col-5 col-form-label d-flex align-items-center">
                    <div className="radio-inline">
                      <label className="radio radio-outline radio-primary">
                        Si
                        <input
                          type="radio"
                          name="medicacion"
                          value="si"
                          onChange={onMedicationValueChange}
                        />
                        <span></span>
                      </label>
                      <label className="radio radio-outline radio-primary">
                        No
                        <input
                          onChange={onMedicationValueChange}
                          type="radio"
                          name="medicacion"
                          value="no"
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                  {!hasMedication && submitted && (
                    <label className="error text-left">
                      Por favor seleccione una opción
                    </label>
                  )}
                </div>
                <div className="form-group">
                  <Dropdown
                    onChange={handleChangeProduct}
                    isRequired
                    hasError={!product && submitted}
                    label="Producto"
                    errorMessage="Por favor seleccione su producto"
                    name="producto"
                    items={productos}
                    placeholder="Seleccionar"
                  />
                </div>
                <div className="form-group">
                  <Dropdown
                    onChange={(e) => setIndication(e.target.value)}
                    isRequired
                    hasError={!indication && submitted}
                    label="Indicacion"
                    errorMessage="Por favor seleccione su indicacion"
                    name="producto"
                    items={indicaciones}
                    placeholder="Seleccionar"
                    value={indication}
                  />
                </div>
                <div className="form-group fv-plugins-icon-container text-left">
                  <label className="checkbox mb-0">
                    <div className="ml-2 text-primary">
                      <Link to="/condiciones-de-uso" target="_blank">
                        He leído y acepto estas condiciones de uso y la política
                        de privacidad<span className="required"> *</span>
                      </Link>
                    </div>
                    <input
                      checked={acceptConditions}
                      onChange={(value) => {
                        setAcceptConditions(value.target.checked);
                      }}
                      type="checkbox"
                      name="condiciones"
                    />
                    {!acceptConditions && submitted && (
                      <label className="error text-left">
                        Por favor tilde el casillero para continuar
                      </label>
                    )}
                    <span></span>
                  </label>
                </div>
                <div className="required-text">
                  <p>* Campos obligatorios</p>
                </div>
                <div className="form-group d-flex flex-wrap justify-content-center justify-content-lg-start pb-lg-0 pb-3">
                  <button
                    ref={refLi}
                    type="button"
                    onClick={toggleModal}
                    disabled={disabled}
                    id="botonEnviar"
                    style={{ fontWeight: "bold" }}
                    className="btn btn-primary font-size-h6 px-6 py-3 my-3 mr-4"
                  >
                    Enviar
                  </button>
                  {/* <!--end::Signup--> */}
                </div>
              </form>
            )}
          </div>
        </FormLayout>
        <Link to="/" className="go-home">
          <FontAwesomeIcon icon={faHome} className="icon-lg text-white" />
        </Link>
      </>
      {show ? <ModalConsentimiento onAcepted={handleSubmit} closeModal={() => setShow(false)} /> : null}
    </div>
  );
};

export default Enrolamiento;
