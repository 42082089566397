const url = process.env.REACT_APP_API_URL;

export const enrolar = async (data) => {
  try {
    const idPrograma = localStorage.getItem("idPrograma");
    data.idPrograma = idPrograma;
    const response = await postData(
      `${url}/PacientePrograma/EnrolarPaciente`,
      data
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};


export const registrar = async (data) => {
  try {
    const response = await postData(`${url}/usuarios`,
      data
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getInformacionPrograma = async () => {
  try {
    const response = await postData(`${url}/programa`, {
    });
    localStorage.setItem("idPrograma", response.datosPrograma.id);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const postData = async (url = "", data = {}) => {
  // Opciones por defecto estan marcadas con un *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

export const  setCookie = (name,value,days)  => {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  const domain = ";domain=elpixel.net"
  document.cookie = name + "=" + (value || "")  + expires + "; path=/" + domain;
}