import React, { useState, useEffect, useRef } from 'react'
import { CusmtomLink } from './Link'
import styles from './Header.module.css'

export const InicioListLink = ({ onClick = () => { } }) => {
  const [show, setShow] = useState(false)
  const refLi = useRef(null)

  const toggleModal = () => {
    setShow(prev => !prev)
  }

  const onHandleClick = () => {
    toggleModal()
    onClick()
  }

  const handleClickOutside = (event) => {
    if (refLi && !refLi.current.contains(event.target)) {
      setShow(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <li
      ref={refLi}
      className={`menu-item menu-item-submenu menu-item-here menu-item-rel menu-item-open-dropdown ${show ? 'menu-item-hover menu-item-open' : ''}`}
      role='menuitem'
    >
      <span onClick={toggleModal} className='menu-link menu-toggle'>
        <span  style={{ color: "#464e5f", fontSize: "15px" }} className={`menu-text ${styles.menu}`}>Inicio</span>
      </span>

      <div className={`menu-submenu menu-submenu-classNameic menu-submenu-right ${styles.subnav}`}>
        <ul className='menu-subnav' style={{backgroundColor:'white', borderRadius:'5px', padding: '20px 0px'}}>
          <CusmtomLink isSimple={true} activeOnlyWhenExact onClick={onHandleClick} to='#contacto' label='Objetivo' />
          <CusmtomLink isSimple={true} activeOnlyWhenExact onClick={onHandleClick} to='#contacto' label='Contacto' />
          
        </ul>
      </div>
    </li>
  )
}