
import styles from './Header.module.css'

import { Link, useMatch } from 'react-router-dom'; 

export const CusmtomLink = ({
  target,
  isSimple = false,
  label,
  to,
  activeOnlyWhenExact,
  onClick = () => {},
}) => {
  // Utiliza useMatch para verificar la coincidencia de la ruta
  const match = useMatch(to); 

  // Define las clases activas
  const activeClass = match ? "menu-item-open menu-item-here menu-item-active" : "";

  return (
    <li className={`menu-item menu-item-submenu menu-item-rel ${activeClass}`}>
      {isSimple ? (
        <a
          style={{ color: "#464e5f", fontSize: "15px" }}
          onClick={onClick}
          className={`menu-link ${styles.menusubnav}`}
          href={to}
        >
          {label}
        </a>
      ) : (
        <Link
          target={target}
          to={to}
          className={`menu-link ${styles.menusubnav}`}
        >
          <span
            className={`menu-text ${styles.menusubnav}`}
            style={{ color: "#464e5f", fontSize: "15px" }}
          >
            {label}
          </span>
        </Link>
      )}
    </li>
  );
};
